








import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockColorMixin } from '@/composables/block-color';

// import { MarketHypeSubscribeBlockDataFragment } from '@/graphql/generated';
import { PageBlockMixin } from '@/modules/cms';

@Component({
  graphqlData: gql`
    fragment MarketHypeSubscribeBlockData on MarketHypeSubscribeBlock {
      id
      title
      lead
      embedCode
      blockColorAttribute {
        bgColor
        textColor
      }
    }
  `,
})
export default class MarketHypeSubscribeBlock extends mixins(PageBlockMixin<any>(), blockColorMixin) {}
