








import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class MarketHypeForm extends Vue {
  @Prop(String)
  embedCode!: string

  mutationObserver: MutationObserver | null = null;

  mounted () {
    this.runScriptOnLoad();
  }

  beforeDestroy () {
    if (this.mutationObserver)
      this.mutationObserver.disconnect();
  }

  runScriptOnLoad () {
    const form = this.$refs.formRef as HTMLFormElement;
    if (!form)
      return setTimeout(() => this.runScriptOnLoad(), 0);

    const script = form.querySelector('script');
    this.initiateForm(script);
  }

  initiateForm (referenceScript: HTMLScriptElement) {
    const form = this.$refs.form as HTMLFormElement;

    // Restore script from reference
    // We can't just clone the script because it will not execute
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
    const script = document.createElement('script');
    script.async = !!referenceScript.async;
    script.src = referenceScript.src;
    script.onload = referenceScript.onload;
    script.innerHTML = referenceScript.innerHTML;
    form.innerHTML = '';
    form.appendChild(script);

    // Remove any added styles
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const addedStyle = Array.from(mutation.addedNodes).find(node => node.nodeName === 'STYLE');
        if (addedStyle) form.removeChild(addedStyle);
      });

      form.querySelectorAll('input').forEach((input) => {
        if (!input.placeholder)
          input.placeholder = ' ';
      });
    });

    this.mutationObserver.observe(form, { childList: true });
  }
}
