





















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({
  components: {
    MarketHypeForm: () => import('@/components/MarketHypeForm.vue'),
  },
})
export default class BlockMarketHypeNewsletter extends Vue {
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  @Prop(String)
  lead!: string

  @Prop(String)
  embedCode!: string
}
